<template>
	<div class="newScheme">
		<el-table class="el-table" height="50px" :data="list" border @select="majorChange" @row-click="majorClick"
			ref="major" :row-style="{ height: '40px' }">
			<el-table-column type="selection"></el-table-column>
			<el-table-column prop="" label="推荐类别"></el-table-column>
			<el-table-column prop="" label="学科门类"></el-table-column>
			<el-table-column prop="subjectName" label="专业类别"></el-table-column>
			<el-table-column prop="subjectCode" label="专业类别代码"></el-table-column>
			<el-table-column min-width="80px" label="本专科">
				<template slot-scope="scope">
					<span>{{ getGategoryName(scope.row.syS_Level, "SYS_Level") }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="introudce" label="专业解读" show-overflow-tooltip></el-table-column>
			<el-table-column prop="course" label="开设课程" show-overflow-tooltip></el-table-column>
			<el-table-column prop="similar" label="相近专业" show-overflow-tooltip></el-table-column>
			<el-table-column prop="jobFutrue" label="就业前景" show-overflow-tooltip></el-table-column>
			<el-table-column prop="jobWays" label="就业方向" show-overflow-tooltip></el-table-column>
		</el-table>

		<el-button type="primary" style="margin: 15px 0; width: 150px">推荐更多专业</el-button>
		<el-table class="el-table" height="50px" :data="showList" ref="dataForm" border :row-style="{ height: '40px' }"
			v-loading="majorLoding">
			<el-table-column prop="subjectName" label="专业类别"></el-table-column>
			<el-table-column prop="subjectCode" label="专业类别代码"></el-table-column>
			<el-table-column min-width="80px" label="本专科">
				<template slot-scope="scope">
					<span>{{ getGategoryName(scope.row.syS_Level, "SYS_Level") }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="introudce" label="专业解读" show-overflow-tooltip></el-table-column>
			<el-table-column prop="course" label="开设课程" show-overflow-tooltip></el-table-column>
			<el-table-column prop="similar" label="相近专业" show-overflow-tooltip></el-table-column>
			<el-table-column prop="jobFutrue" label="就业前景" show-overflow-tooltip></el-table-column>
			<el-table-column prop="jobWays" label="就业方向" show-overflow-tooltip></el-table-column>
			<el-table-column prop="notes" label="备注"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.$index, scope.row)"
						size="mini">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="btns">
			<el-button type="primary" v-if="this.updateButton == false" size="small" @click="handleCommit">暂存
			</el-button>
			<el-button type="primary" v-else-if="this.updateButton == true" size="small" @click="updateCommit">修改
			</el-button>
			<!--      <el-button type="primary" @click="sendLifeplan" size="small"
        >发送</el-button
      > -->
			<el-button type="primary" @click="$router.back()" size="small">返回</el-button>
		</div>
	</div>
</template>

<script>
	import * as subjects from "@/api/subjects";
	import * as categorys from "@/api/categorys";
	import * as lifeplans from "@/api/lifeplans";
	export default {
		name: "subjects",
		data() {
			return {
				majorLoding: false,
				sendCheck: false,
				listQuery: {
					// 查询条件
					page: 1,
					limit: 999,
					key: undefined,
					appId: undefined,
					leftSubjectCode: "",
					subjectCode: "", // 专业代码
					subjectName: "", // 专业名称
					minSubjectLevel: "", // 最小层级
					maxSubjectLevel: "", // 最大层级
					syS_Level: "", // 本专科
					subjectLevel: "", // 层级
					degree: "", // 学位
					minStudyYear: "", // 最小修学年限
					maxStudyYear: "", // 最大修学年限
					introudce: "", // 专业解读
					course: "", // 开设课程
					similar: "", // 相近专业
					jobFutrue: "", // 就业前景
					jobWays: "", // 就业方向
					select: false,
				},
				temp: {
					subjectCode: "", // 专业代码
					subjectName: "", // 专业名称
					subjectLevel: "", // 层级
					syS_Level: "", // 本专科
					degree: "", // 学位
					studyYear: "", // 修学年限
					introudce: "", // 专业解读
					course: "", // 开设课程
					similar: "", // 相近专业
					jobFutrue: "", // 就业前景
					jobWays: "", // 就业方向
					extendInfo: "", // 其他信息,防止最后加逗号，可以删除
					mobileTel: "",
				},
				majorList: [{
					rec: "金牌专业类别",
					subject: "经济学",
					majorCate: "经济学类",
					code: "0201",
					introduce: "经济学主要是研究人类...",
					core: "经济学基础、财经学...",
					employment: "银行、各金融领域监督管理...",
					select: false,
				}, ],
				majorCurrent: [],
				listVar: ["测试一", "测试二", "测试三"],
				addNewlist: [],
				userMobelTel: "",
				showList: [],
				getLifepListID: [], //用来获取LifePlanDetail的ID
				list: null,
				total: 0,
				listLoading: true,
				updateButton: false,
				gategoryList: [],
				//collectVal: [], //收集复选框id是否勾选或删除
				getSchemeId: "", //获取方案库传过来的id
				planName: "",
				schemeList: [{
					rec: "老师推荐",
					majorCate: "经济学类",
					major: "经济学",
					code: "020101",
					generalization: "经济学是...",
					core: "经济学基础...",
					employment: "就业行业主要包括...",
					comment: "如果考生在报考...",
					notes: "",
				}, ],
			};
		},
		created() {
			this.getSchemeId = "";
			this.getSchemeId = this.$route.query.rowid;
			console.log(this.getSchemeId);
			if (this.getSchemeId != "" && this.getSchemeId != undefined) {
				//console.log(this.getSchemeId);
				this.updateButton = true;
				this.getLevelList();
			} else {
				this.getLevelList();
				this.updateButton = false;
				console.log("success");
			}
		},
		activated() {
			//activated会在切换页面时执行方法
			this.getSchemeId = "";
			this.getSchemeId = this.$route.query.rowid;
			console.log(this.getSchemeId);
			if (this.getSchemeId != "" && this.getSchemeId != undefined) {
				//console.log(this.getSchemeId);
				this.updateButton = true;
				this.getLevelList();
			} else {
				this.getLevelList();
				this.updateButton = false;
				console.log("success");
			}
			//this.getGategorys();
		},
		methods: {
			// sendLifeplan() {
			//   if (this.sendCheck == false) {
			//     this.$message("请先暂存在进行发送");
			//   } else {
			//     console.log("123");
			//   }
			// },
			// 未分配选中
			majorChange(val) {
				console.log(val);
				this.majorCurrent = val;
				if (val.length == 0) {
					this.showList = [];
				} else {
					this.majorLoding = true;
					this.addNewlist = [];
					this.showList = [];
					//this.checkgetLifepListID(val);
					for (let i = 0; i < val.length; i++) {
						setTimeout(() => {
							this.getsubject(val[i]);
						}, 100);
					}
				}
				//console.log(this.showList);
			},
			subjectClear() {
				this.showList = [];
				console.log("this.list");
				console.log(this.list);
				if (this.list != null) {
					for (let i = 0; i < this.list.length; i++) {
						if (this.list[i].select == true) {
							this.$refs.major.toggleRowSelection(this.list[i], false);
							this.list[i].select = false;
						}
					}
				}
			},
			checkgetLifepListID(val) {
				if (this.getLifepListID.length != 0) {
					for (let i = 0; i < val.length; i++) {
						this.getLifepListID = this.removeShowList(val[i]);
					}
				}
			},
			majorClick(row) {
				//console.log(row);

				let index = this.list.findIndex((item) => {
					//console.log('error')
					return item.subjectCode == row.subjectCode;
				});

				//this.majorCurrent = row;
				//console.log(index);
				this.$refs.major.toggleRowSelection(row, !this.list[index].select); //设置复选框状态
				this.list[index].select = !this.list[index].select;
				//console.log(row);
				if (row.select == true) {
					// if (this.getLifepListID.length != 0) {
					//   console.log("getLifepListID");
					//   // for (let i = 0; i < this.getLifepListID.length; i++) {
					//   //   let alllifeSubject = this.getLifepListID[i].subjectCode.slice(0, 4);
					//   //   if (row.subjectCode == alllifeSubject) {
					//   //     this.getLifepListID.splice(i, 1);
					//   //   }
					//   // }
					//   //this.getLifepListID = this.removeShowList(row, this.getLifepListID);
					// }
					setTimeout(() => {
						this.getsubject(row);
					}, 100);
				} else {
					this.showList = this.removeShowList(row, this.showList);
				}
			},
			removeShowList(row, theslist) {
				let newthelist = JSON.parse(JSON.stringify(theslist)); //赋值之后不会进行增删改也不会改变js原数组的位置
				console.log(newthelist);
				for (let i = 0; i < theslist.length; i++) {
					let index = newthelist.findIndex((item) => {
						let alllifeSubject = item.subjectCode.slice(0, 4);
						return alllifeSubject == row.subjectCode;
					});
					console.log(index);
					if (index !== -1) {
						newthelist.splice(index, 1);
					}
				}
				return newthelist;
			},
			handleDelete(index) {
				//console.log(this.searchList);
				this.showList.splice(index, 1);
				// if (this.getLifepListID != null || this.getLifepListID != undefined) {
				//   let indexs = this.getLifepListID.findIndex((item) => {
				//     return item.subjectCode == row.subjectCode;
				//   });
				//   if (indexs != -1) {
				//     this.getLifepListID.splice(indexs, 1);
				//     console.log(this.getLifepListID);
				//   }
				// }
			},
			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};
				console.log("getcategorys");
				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
					console.log("getcategorys2");
				});
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				if (!dicobject) {
					//console.log("dicobject is null");
					return "";
				} else {
					return dicobject.name;
				}
			},
			getList(val) {
				this.listQuery.leftSubjectCode = val;
				//this.collectVal = [];
				//let lists = [];
				subjects.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					for (let j = 0; j < list1.length; j++) {
						var o = list1[j];
						if (o.subjectLevel > 2) {
							//lists.push(o);
							this.showList.push(o);
						}
					}
					this.majorLoding = false;
				});
			},
			getLevelList() {
				this.list = [];
				this.listLoading = true;
				let listQuery = {
					page: 1,
					limit: 999,
				};
				subjects.getList(listQuery).then((response) => {
					let list1 = response.data;
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];

						if (o.subjectLevel <= 2) {
							this.list.push(o);
							//console.log(o);
						}
					}
					//console.log(this.list);
					this.total = response.count;
					this.listLoading = false;
					if (this.getSchemeId != undefined && this.getSchemeId != "") {
						//console.log(this.getSchemeId);
						setTimeout(() => {
							this.updategetLevelList(this.getSchemeId);
						}, 1000);
					}
					//console.log("this total is" + this.list.length);
				});
			},
			updategetLevelList(id) {
				//this.list = [];
				this.listLoading = true;
				var getthisid = id;
				console.log(getthisid);
				lifeplans
					.getLifePlan(getthisid)
					.then((response) => {
						console.log(response);
						let list1 = response.result.lifePlanDetails;
						this.getLifepListID = response.result.lifePlanDetails;
						//console.log(this.getLifepListID);
						this.planName = response.result.planName;

						let getvalList = [];
						let alllifeSubject = list1[0].subjectCode.slice(0, 4);
						getvalList.push(list1[0].subjectCode.slice(0, 4));
						for (let i = 0; i < list1.length; i++) {
							let nextlifeSubject = list1[i].subjectCode.slice(0, 4);
							if (alllifeSubject != nextlifeSubject) {
								// setTimeout(() => {
								//   this.selectCheckList(nextlifeSubject);
								// }, 1000);
								let index = getvalList.findIndex((item) => {
									return item == nextlifeSubject;
								});
								if (index == -1) {
									getvalList.push(nextlifeSubject);
									alllifeSubject = nextlifeSubject;
								}
							}
						}
						console.log(getvalList);
						for (let t = 0; t < getvalList.length; t++) {
							setTimeout(() => {
								this.selectCheckList(getvalList[t]);
							}, 1000);
						}

						//var numi = 0;
						var listtest = [];
						setTimeout(() => {
							let newthelist = JSON.parse(JSON.stringify(this.showList));
							for (let l = 0; l < newthelist.length; l++) {
								//numi = 0;
								let index = list1.findIndex((item) => {
									return item.subjectCode == newthelist[l].subjectCode;
								});
								//console.log(index);
								if (index !== -1) {
									newthelist[l].id = list1[index].id;
									// console.log(list1[index].id);
									//console.log(newthelist[l].id);
									listtest.push(newthelist[l]);
								}
								// while (numi < list1.length) {
								//   if (this.showList[l].subjectCode == list1[numi].subjectCode) {
								//     this.showList[l].id = list1[numi].id;
								//     listtest.push(this.showList[l]);
								//   }
								//   numi += 1;
								// }
							}
							this.showList = [];
							console.log(listtest);
							//this.showList =JSON.parse(JSON.stringify(listtest));
							this.showList = listtest.map((item) => {
								return item;
							})
							console.log(this.showList);
						}, 2000);
					})
					.catch((res) => {
						this.$message.error(res);
					});
			},
			selectCheckList(val) {
				let index = this.list.findIndex((item) => {
					//console.log(item);
					return item.subjectCode == val;
				});
				this.$refs.major.toggleRowSelection(this.list[index], true); //设置复选框状态
				this.list[index].select = true;
				this.getList(val);
				// setTimeout(() => {

				// }, 800);
			},
			handleCommit() {
				//提交
				this.addNewlist = [];
				let list = {};
				this.userMobelTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				console.log(this.userMobelTel);

				var lifelist = [];
				var isnum = 0;
				console.log(this.showList);
				while (isnum < this.showList.length) {
					lifelist.push({
						id: "",
						SubjectCode: this.showList[isnum].subjectCode,
					});
					isnum += 1;
				}
				//console.log(lifelist);
				list = {
					id: "",
					MobileTel: this.userMobelTel,
					LifePlanDetails: lifelist,
				};
				//this.addNewlist.push(list);
				console.log(list);
				lifeplans
					.addNew(list)
					.then((res) => {
						console.log(res);
						if (res.code == 200) {
							//this.list.unshift(this.addNewlist);
							this.$notify({
								title: "成功",
								message: "创建成功",
								type: "success",
								duration: 2000,
							});
							//this.showList = [];
							this.$emit("closeEdit");
							setTimeout(() => {
								this.getNewSchemid();
							}, 1000);
						} else {
							//this.showList = [];
							this.$message.error(res.message);
							this.$emit("closeEdit");
						}
					})
					.catch((res) => {
						console.log(res);
						this.$message.error(res.message);
					});
			},
			updateCommit() {
				let list = {};
				this.userMobelTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				console.log(this.userMobelTel);
				var lifelist = [];
				var isnum = 0;
				console.log(this.showList);
				// if (this.getLifepListID.length > 0) {
				//   for (let i = 0; i < this.getLifepListID.length; i++) {
				//     lifelist.push({
				//       id: this.getLifepListID[i].id,
				//       subjectCode: this.getLifepListID[i].subjectCode,
				//     });
				//   }
				// }
				while (isnum < this.showList.length) {
					let index = this.getLifepListID.findIndex((item) => {
						return item.id == this.showList[isnum].id;
					});
					if (index !== -1) {
						lifelist.push({
							id: this.showList[isnum].id,
							subjectCode: this.showList[isnum].subjectCode,
						});
					} else {
						lifelist.push({
							id: "",
							subjectCode: this.showList[isnum].subjectCode,
						});
					}
					isnum += 1;
				}
				console.log(lifelist);
				list = {
					id: this.getSchemeId,
					mobileTel: this.userMobelTel,
					lifePlanDetails: lifelist,
				};
				console.log(list);
				lifeplans
					.updateNew(list)
					.then((res) => {
						console.log(res);
						if (res.code == 200) {
							//this.list.unshift(this.addNewlist);
							this.$notify({
								title: "成功",
								message: "修改成功",
								type: "success",
								duration: 2000,
							});
							this.showList = [];
							this.$emit("closeEdit");
						} else {
							this.showList = [];
							this.$message.error(res.message);
							this.$emit("closeEdit");
						}
					})
					.catch((res) => {
						console.log(res);
						this.$message.error(res.message);
					});
			},
			sleep(ms) {
				//sleep延迟方法2
				var unixtime_ms = new Date().getTime();
				while (new Date().getTime() < unixtime_ms + ms) {
					console.log("1");
				}
			},
			getsubject(val) {
				this.listQuery.leftSubjectCode = val.subjectCode;
				//this.collectVal = [];
				//let lists = [];
				subjects.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					for (let j = 0; j < list1.length; j++) {
						var o = list1[j];
						if (o.subjectLevel > 2) {
							//lists.push(o);
							this.showList.push(o);
						}
					}
					this.majorLoding = false;
				});
			},
			getNewSchemid() {
				this.temp = {
					page: 1,
					limit: 99,
				};
				this.temp.mobileTel = JSON.parse(
					localStorage.getItem("stuInfo")
				).mobileTel;
				console.log(this.temp);
				lifeplans
					.getList(this.temp)
					.then((res) => {
						console.log(res);
						let list1 = res.data;
						this.getSchemeId = list1[list1.length - 1].id;
						console.log(this.getSchemeId);
					})
					.catch((res) => {
						console.log(res);
					});
			},
			sendLifeplan() {
				if (this.getSchemeId == "" || this.getSchemeId == undefined) {
					this.$message.error("发送操作失败,请先进行暂存或者修改！");
					return;
				} else {
					lifeplans
						.send(this.getSchemeId)
						.then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$notify({
									message: "发送成功",
									type: "success",
									duration: 2000,
								});
							}
							this.showList = [];
							this.getSchemedId = "";
						})
						.catch((res) => {
							this.$message.error(res.message);
						});
				}
			},
		},
		beforeDestroy() {
			console.log("关闭");
			this.majorCurrent = [];
		},
		computed: {
			stuinfo() {
				//console.log("123");
				return this.$store.state.stu.stuInfo;
			},
		},
		watch: {
			test() {
				//this.getList(val);
				//console.log(this.showList);
				// for (let i = 0; i < val.length; i++) {
				//   // let index = this.showList.findIndex((item) => {
				//   //   return item.majorCate == val[i].majorCate;
				//   // });
				//   // console.log(val[i]);
				//   // if (index == -1) {
				//   //   for (let j = 0; j < this.schemeList.length; j++) {
				//   //     if (val[i].majorCate == this.schemeList[j].majorCate) {
				//   //       this.showList.push(this.schemeList[j]);
				//   //     }
				//   //   }
				//   // }
				// }
				//this.listQuery = val;
				// console.log(val[0].subjectCode);
				//this.showList.push(val[0]);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.newScheme {
		padding: 15px;
		display: flex;
		flex-direction: column;
		height: calc(100% - 30px);

		.el-table {
			flex: 1;
		}

		.el-table .el-table__body-rapper {
			width: 100%;
			height: calc(100% - 100px);
			overflow: auto;
		}

		.btns {
			text-align: center;
			margin: 15px 0;

			.el-button {
				width: 70px;
				margin-left: 15px;
			}
		}

		.newdiv {
			width: 100%;
			height: 20px;
		}
	}
</style>
